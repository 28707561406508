import React from "react"
import Styles from "./css/uninstalled.module.scss"


class UpdatedPrivacy extends React.Component {
  constructor(props){
    super(props);
    if(props.data){
      this.state = {
        data: props.data
      }
    }
  }

  render() {
    return (
      <section>

        <div className ={Styles.unInstalled}>
        <header>
		<img alt="Easy Share Logo" src="/img/logo.png" />
	</header>
	<main>
		<h1>Sorry to see you go</h1>
		<h2>Easy Share is uninstalled. If you change your mind, click below to reinstall.</h2><button>Add to Chrome</button>
		<p>This free extension will update this browser’s new tab page. By continuing, you acknowledge the Terms of Service and Privacy Policy, and that you may be presented with more optional offers.</p>
		<footer>
			<ul>
				<li>Tightrope Interactive</li>
				<li>
					<a href="https://geteasyshare.app/terms-of-service" target="_blank">Terms</a>
				</li>
				<li>
					<a href="https://geteasyshare.app/privacy-policy" target="_blank">Privacy</a>
				</li>
				<li>
					<a href="https://geteasyshare.app/uninstall" target="_blank">Uninstall</a>
				</li>
				<li>
					<a href="https://geteasyshare.app/contact-us" target="_blank">Contact</a>
				</li>
				<li>All trademarks are property of their respective owners</li>
				<li>
					<a href="https://geteasyshare.app/privacy-policy#cali-consumer" target="_blank">Do Not Sell My Personal Information</a>
				</li>
			</ul>
		</footer>
	</main>
	<div class={Styles.chatBubble}>
		<div class={Styles.typing}>
			<div class={Styles.dot}></div>
			<div class={Styles.dot}></div>
			<div class={Styles.dot}></div>
		</div>
	</div>
	<script>
	</script>
        </div>
      </section>
    )
  }
}
export default UpdatedPrivacy
